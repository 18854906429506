import React from 'react'
import styled from 'styled-components';
import { Link } from 'gatsby'
import { Tracking } from '../model/utils';

export const TicketsIcon = () => (
  <svg enableBackground="new 0 0 512 512" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <g transform="rotate(-45, 256, 256)" >
        <path d="m492.41 210.31c10.917-0.337 19.592-9.284 19.592-20.206v-77.338c0-11.164-9.051-20.216-20.216-20.216h-471.57c-11.165 1e-3 -20.216 9.052-20.216 20.216v77.287c0 11.164 9.051 20.216 20.216 20.216 25.218 0 45.734 20.516 45.734 45.734s-20.516 45.734-45.734 45.734c-11.165 0-20.216 9.052-20.216 20.216v77.287c0 11.164 9.051 20.216 20.216 20.216h471.57c11.164 0 20.216-9.051 20.216-20.216v-77.338c0-10.922-8.675-19.869-19.592-20.206-24.836-0.767-44.29-20.837-44.29-45.693s19.454-44.926 44.289-45.693zm-329.66 168.71h-122.31v-39.249c37.792-9.12 65.95-43.218 65.95-83.771 0-40.552-28.158-74.652-65.95-83.772v-39.249h122.31v246.04zm269.3-62.995c10.997 11.315 24.617 19.251 39.521 23.217v39.778h-268.39v-246.04h268.39v39.778c-14.904 3.966-28.524 11.903-39.521 23.217-15.709 16.164-24.361 37.482-24.361 60.027-1e-3 22.543 8.652 43.86 24.362 60.024z"/>
        <path d="m372.24 198.67h-126.04c-11.164 0-20.216 9.051-20.216 20.216s9.051 20.216 20.216 20.216h126.04c11.164 0 20.216-9.051 20.216-20.216s-9.052-20.216-20.216-20.216z"/>
        <path d="m372.24 272.9h-126.04c-11.164 0-20.216 9.051-20.216 20.216s9.051 20.216 20.216 20.216h126.04c11.164 0 20.216-9.051 20.216-20.216s-9.052-20.216-20.216-20.216z"/>
        </g>
  </svg>
)

const FloatButton = styled.div`
@media (min-width: 700px) {
  transform: scale(1.2);
}
  position: fixed;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  bottom: 24px;
  right: 24px;
  height: 42px;
  padding: 9px;
  border-radius: 24px;
  background-color: ${props => props.theme.colors.highlight};
  box-shadow: 0px 1px 4px 2px #333;
  &:active {
    background-color: ${props => props.theme.colors.attenuate};
  }
  z-index: 100000;
  & svg {
    display: flex;
    width: 24px;
    height: 24px;
    fill: #333;
  }
`

export const TicketButton = () => {
  return (
    <Link to="/biglietti" onClick={() => {
      Tracking.trackEngagement('box-office#open', window.location.pathname)
    }}>
      <FloatButton>
        <TicketsIcon/>
      </FloatButton>
    </Link>
  )
}
