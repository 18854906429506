/// <reference path="../../typings/index.d.ts"/>

import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import bgPattern from '../images/bg_pattern.png'
import { CinemaId, CINEMA_META } from '../model/model';
import { Tracking } from '../model/utils';

const Container = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 960px;
  width: 100%;
  margin: auto;
  padding: 1.3rem 0 0.8rem 0;
  @media (min-width: 700px) {
    padding: 1.4rem 0 1rem 0;
    text-align: right;
  }
  color: #CCCCCC;
  font-weight: 100;
  background: linear-gradient(rgba(18, 18, 18, 1), rgba(18, 18, 18, 0) 10%, rgba(18, 18, 18, 0)), url(${bgPattern});
`

const Items = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 90%;
  list-style: none;
  text-align: center;
  margin: auto;
  margin-bottom: 1rem;
`

const Item = styled.li`
  margin: 0;
  padding: 0.3rem;
`

const Outline = styled.a`
  display: block;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  padding: 2px;
  & svg {
    fill: white;
  }
`

const WhatsAppOutline = styled(Outline)`
  background-color: #4dc247;
`

const MessangerOutline = styled(Outline)`
background-color: #0084ff;
`

const WhatsappButton = ({href}) => (
  <WhatsAppOutline href={href} onClick={() => {Tracking.track({category: 'contact', action: 'whatsapp'})} }>
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32">
      <path d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z" fillRule="evenodd"></path>
    </svg>
  </WhatsAppOutline>
)

const MessangerButton = ({href}) => (
  <MessangerOutline href={href} onClick={() => {Tracking.track({category: 'contact', action: 'messanger'})} }>
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32">
      <path d=" M16 6C9.925 6 5 10.56 5 16.185c0 3.205 1.6 6.065 4.1 7.932V28l3.745-2.056c1 .277 2.058.426 3.155.426 6.075 0 11-4.56 11-10.185C27 10.56 22.075 6 16 6zm1.093 13.716l-2.8-2.988-5.467 2.988 6.013-6.383 2.868 2.988 5.398-2.987-6.013 6.383z" fillRule="evenodd"></path>
    </svg>
  </MessangerOutline>
)

const PhoneOutline = styled(Outline)`
background-color: #009900;
`
const PhoneButton = ({href}) => (
  <PhoneOutline href={href} onClick={() => {Tracking.track({category: 'contact', action: 'telephone'})} }>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"
      style={{width:"32px", height:"32px", margin: "4px"}}>
      <path d="M704 640c-64 64-64 128-128 128s-128-64-192-128-128-128-128-192 64-64 128-128-128-256-192-256-192 192-192 192c0 128 131.5 387.5 256 512s384 256 512 256c0 0 192-128 192-192s-192-256-256-192z"/>
    </svg>
  </PhoneOutline>
)

interface FooterProps {
  mainCinemaId: CinemaId
}
const Footer: React.SFC<FooterProps> = ({mainCinemaId}) => (
  <Container>
    <Items>
      <Item><PhoneButton href={"tel:+39" + CINEMA_META[mainCinemaId].phone.replace(' ', '')}/></Item>
      <Item><MessangerButton href="https://m.me/149938445075571"/></Item>
      <Item><WhatsappButton href="https://wa.me/393341539100"/></Item>
    </Items>
  </Container>
)

export default Footer
