export function assertNever(x: never): never {
  throw new Error("Assertion failure: " + x);
}

export const assertNotNull = <T>(x: T | null | undefined): T => {
  if (x == null || x == undefined) {
    throw new Error("Assertion failure: " + x);
  }
  return x
}

const chars = 'abcdefghijklmnopqrstuvwyxzABCDEFGHIJKLMNOPQRSTUVWYXZ1234567890';
export const randomString = (len = 5) =>
  new Array(len)
    .fill('')
    .map(() => chars.charAt(Math.floor(Math.random() * chars.length)))
    .join('');

    
export const isNotNull = <T>(elem: T | null): elem is T => {
  return elem != null
}

export const isNotEmpty = (elem: string | null): elem is string => {
  return !isEmpty(elem)
}

export const isEmpty = (elem: string | null | undefined): elem is null | undefined => {
  return elem == null || elem.trim().length === 0
}

export const lintStringList = (list: Array<string | null> | null): Array<string> => {
  return list == null
    ? []
    : list.filter(isNotNull).filter(isNotEmpty)
}

export const lintList = <T>(list: Array<T | null> | null): Array<T> => {
  return list == null
    ? []
    : list.filter(isNotNull)
}

function escapeRegExp(str) {
  return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
}

export const replaceAll = (find: string, replace: string) => (str: string): string => {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}


export interface TrackingEvent {
  category: string
  action: string
  label?: string
  value?: string
}

export const Tracking = {
  track: (event: TrackingEvent | null | undefined): void => {
    if (event == null) {
      return
    }
    const logger = (window as any).dataLayer || []
    logger.push({
      event: 'tracking-event',
      ...event
    })
  },
  trackEngagement: (widget: string, goal: string, extraInfo?: string): void => {
    Tracking.track({category: 'engagement', action: widget, label: goal, value: extraInfo})
  },
  trackNavigation: (widget: string, to: string, extraInfo?: string): void => {
    Tracking.track({category: 'navigation', action: widget, label: to, value: extraInfo})
  }
}


const EXCLUDE_PATHS = ['/404', '/informazioni', '/privacy-policy']
export const Sharing = {
  shouldDisplaySharing: () => {
    if (typeof window !== 'undefined') {
      const path = (window as any).location.pathname || '/'
      const supportsSharing = (navigator as any).share !== undefined || (navigator as any).clipboard !== undefined
      return supportsSharing && !EXCLUDE_PATHS.includes(path)
    } else {
      return false
    }
  },
  sharePage: async (): Promise<'native' | 'clipboard'> => {
    const share = (navigator as any).share !== undefined
      ? (url) => (navigator as any).share({url: url}).then(() => 'native')
      : (navigator as any).clipboard !== undefined
        ? (url) => (navigator as any).clipboard.writeText(url).then(() => 'clipboard')
        : null

    if (share != null) {
      Tracking.trackEngagement('share#requested', window.location.pathname)
      return share(window.location.href)
        .then(type => {
          Tracking.trackEngagement('share#completed', window.location.pathname, type)
          return type
        })
        .catch(() => Tracking.trackEngagement('share#aborted', window.location.pathname))
    }
    return new Promise((res, rej) => rej('Sharing not supported'))
  }
}
