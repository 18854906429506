import * as R from 'ramda'
import React from 'react'
import LazyLoad from 'react-lazyload'
import { Image } from '../model/model'
import { assertNever } from '../model/utils';

interface RImageProps {
  image: Image
  alt: string
  sizes: Array<{viewport: number, size: number}>
  maxSize: number
  width?: string
  height?: string
  mode: 'eager' | 'lazy' | 'stub'
  className?: string
}
const RImage: React.SFC<RImageProps> = ({image, width, height, sizes, maxSize, alt, className, mode}) => {
  const srcset = image.sizes
    .filter(size => size.name !== 'thumbnail')
    .sort((a, b) => a.width! - b.width!)
    .map (size => {
      const width = size.width == null
        ? ''
        : size.width + 'w'
      return size.url + ' ' + width
    })
  const elSizes = [
    ...sizes.map(s => `(max-width: ${s.viewport}px) ${s.size}px`),
    `${maxSize}px`
  ]
  const main = R.sortBy(i => 10000000 - (i.width || 0), image.sizes)[0].url

  const placeholder = <div className={className} style={{
    backgroundSize: '100% 100%',
    backgroundImage: `url(data:image/gif;base64,${image.tinyThumbnail})`,
    width: width,
    height: height
  }} />

  const actual = <img className={className} style={{
      backgroundSize: '100% 100%',
      backgroundImage: `url(data:image/gif;base64,${image.tinyThumbnail})`,
      width: width,
      height: height
    }}
    alt={alt}
    srcSet={srcset.join(',')}
    sizes={elSizes.join(',')}
    src={main} />

  switch(mode) {
    case 'lazy':
      return <LazyLoad placeholder={placeholder} once offset={200}>{actual}</LazyLoad>
    case 'eager':
      return actual
    case 'stub':
      return placeholder
    default:
      assertNever(mode)
      throw new Error('Unexpected mode: ' + mode)
  }
}

export default RImage
