import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { Sharing } from '../model/utils';

export const ShareIcon = () => (
  <svg width="512" height="512" viewBox="0 0 551.13 551.13" xmlns="http://www.w3.org/2000/svg">
    <path d="m465.016 172.228h-51.668v34.446h34.446v310.011h-344.457v-310.011h34.446v-34.446h-51.669c-9.52 0-17.223 7.703-17.223 17.223v344.456c0 9.52 7.703 17.223 17.223 17.223h378.902c9.52 0 17.223-7.703 17.223-17.223v-344.456c0-9.52-7.703-17.223-17.223-17.223z"/>
    <path d="m258.342 65.931v244.08h34.446v-244.08l73.937 73.937 24.354-24.354-115.514-115.514-115.514 115.514 24.354 24.354z"/>
  </svg>
)

const FloatButton = styled.div`
  @media (min-width: 700px) {
    display: none;
  }
  position: fixed;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  bottom: 24px;
  left: 24px;
  height: 42px;
  padding: 9px;
  border-radius: 24px;
  background-color: #36362E;
  z-index: 100000;
  &:active {
    background-color: ${props => props.theme.colors.attenuate};
  }
  & svg {
    display: flex;
    width: 24px;
    height: 24px;
    fill: white;
  }
`
const ShareMessage = styled.span<{show: boolean}>`
  display: flex;
  cursor: default;
  ${props => props.show
  ? `
    margin-right: 12px;
    margin-left: 12px;
    width: fit-content;
    max-width: unset;
    opacity: 1;
    `
  : `
    margin-right: 0;
    margin-left: 0;
    width: 0;
    max-width: 0;
    overflow: hidden;
    opacity: 0;
    `
  }
  transition: 500ms ease-out;
`

export const ShareWidget = () => {
  const [showMessage, setShowMessage] = useState(false)
  useEffect(() => {
    if (showMessage) {
      const tId = setTimeout(() => setShowMessage(false), 2000)
      return () => clearTimeout(tId)
    }
    return undefined
  }, [showMessage])
  if (!Sharing.shouldDisplaySharing()) {
    return null
  }
  const handleClick = () => {
    Sharing.sharePage()
      .then((type) => type === 'clipboard' && setShowMessage(true))
  }
  return (
    <FloatButton onClick={handleClick}>
      <ShareMessage show={showMessage}>Link copiato</ShareMessage>
      <ShareIcon/>
    </FloatButton>
  )
}
