/// <reference path="../../typings/index.d.ts"/>

import React from 'react'
import styled from 'styled-components'
import { Link, StaticQuery, graphql } from 'gatsby'
import bgPattern from '../images/bg_pattern.png'
import Nav from './navbar'
import { Cinema, CinemasMap } from '../model/model'
import { Row, SmartLink } from './utils'
import RImage from './image';
import { Tracking } from '../model/utils';

const SiteHeader = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 960px;
  @media (min-width: 700px) {
    margin: auto;
    padding: 1.2em 0.875rem 1.2rem 0.875rem;
  }
  background: linear-gradient(rgba(18, 18, 18, 0), rgba(18, 18, 18, 0) 80%, rgba(18, 18, 18, 1)), url(${bgPattern});
`

const Container = styled(Row)`
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0.4rem 0;
  width: 100%;
`

const SMALL_LOGO_WIDTH = 130
const LOGO_RATIO = 2.0681818182

const LogoWrapper = styled.div`
  flex-shrink: 0;
  margin-top: 5px;
  margin-left: calc(50% - ${SMALL_LOGO_WIDTH / 2}px);
  @media (min-width: 700px) {
    margin-top: -5px;
    margin-left: 0;
  }
`
const LogoImage = styled.img`
  width: ${SMALL_LOGO_WIDTH}px;
  height: ${SMALL_LOGO_WIDTH / LOGO_RATIO}px;
  @media (min-width: 700px) {
    width: 210px;
    height: ${210 / LOGO_RATIO}px;
  }
`

const Logo: React.SFC<{cinema: Cinema}> = ({cinema}) => (
  <StaticQuery
  query={graphql`
    query {
      mds: file(relativePath: { eq: "logo-mds.png" }) {
        childImageSharp {
          fixed(width: 210) {
            src
          }
        }
      }
      odeon6: file(relativePath: { eq: "logo-odeon6.png" }) {
        childImageSharp {
          fixed(width: 210) {
            src
          }
        }
      }
    }
  `}
  render={data => (
  <LogoWrapper>
    <Link to="/">
      <LogoImage src={data[cinema.id].childImageSharp.fixed.src} alt="Logo"/>
    </Link>
  </LogoWrapper>
  )} />
)

const MobileBackNavigation = styled.div`
  position: absolute;
  left: 5px;
  top: 20px;
  width: 30px;
  height: 30px;
  @media(min-width: 700px) {
    display: none;
  }
`

const MobileBackButton = () => (
  <MobileBackNavigation role='Link' onClick={() => { window.history.go(-1) }}>
    <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
      <path d="M 20,5 L 10,15 L 20,25" stroke="#FFF" strokeWidth="2" fill="none"/>
    </svg>
  </MobileBackNavigation>
)

const BannerImg = styled(RImage)`
display: none;
object-fit: contain;
@media(min-width: 700px) {
  display: flex;
}
`

interface HeaderProps {
  showBackButton: boolean
  cinemas: Cinema[]
}
const Header: React.SFC<HeaderProps> = ({showBackButton, cinemas}) => {
  return (
    <SiteHeader>
      <Container>
        { showBackButton
        ? <MobileBackButton/>
        : null
        }
        <Logo cinema={cinemas[0]}/>
        { cinemas[0].banner == null
        ? null
        : <SmartLink url={cinemas[0].banner.link} mainCinemaId={cinemas[0].id}
            onClick={() => {Tracking.trackNavigation('header/banner', cinemas[0].banner!.link!)}}>
            <BannerImg mode='eager' image={cinemas[0].banner.image} alt="Banner"
              sizes={[{viewport: 350, size: 350}, {viewport: 700, size: 700}]}
              maxSize={720} />
          </SmartLink>
        }
      </Container>
      <Row>
        <Nav cinemas={cinemas}/>
      </Row>
    </SiteHeader>
  )
}

export default Header
