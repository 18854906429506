import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import Header from './header'
import './layout.css'
import Footer from './footer'
import { Cinema, CinemaId, CinemasMap } from '../model/model'
import { graphql } from 'gatsby'
import { gqlToCinema } from '../model/gql-paser'
import { isEmpty } from '../model/utils';
import { cinemaToMeta, PageMeta } from './utils';
import { Location } from '@reach/router';
import { ShareWidget } from './share-widget';
import { TicketButton } from './ticket-button'

const Page = styled.div`
  flex-direction: column;
  margin: 0 auto;
  max-width: 960px;
  padding: 0px;
  background-color: rgba(18, 18, 18, 1);
`

interface DesktopMarginProps {
  topMargin: number
  backgroundImage: string | null
}
const DesktopMargin = styled.div<DesktopMarginProps>`
  @media (min-width: 1000px) {
    ${props => `padding-top: ${props.topMargin}px;`}
    ${props => props.backgroundImage == null
      ? ''
      : `background-image: url(${props.backgroundImage});
         background-repeat: no-repeat;
         background-position: top center;
         background-attachment: fixed;`
    }
  }
`

interface LayoutProps {
  showBackButton: boolean
  cinemas: Cinema[]
  title: string
  meta?: PageMeta
}
const Layout: React.SFC<LayoutProps> = ({ showBackButton, meta, cinemas, title, children }) => {
  const m = meta || cinemaToMeta(cinemas[0])
  return (
    <DesktopMargin topMargin={cinemas[0].topMargin} backgroundImage={cinemas[0].backgroundImage}>
      <Location>
      {props => (
      <Helmet
        title={ isEmpty(title)
          ? cinemas[0].name
          : title + ' - ' + cinemas[0].name
        }
        meta={[
          { name: 'keywords', content: m.keywords },
          { name: 'title', content: m.title},
          { name: 'description', content: m.description},
          { property: 'og:type', content: m.type },
          { property: 'og:url', content: m.url || props.location.href },
          { property: 'og:title', content: m.title },
          { property: 'og:description', content: m.description },
          { property: 'og:image', content: m.image.url },
          { property: 'og:image:width', content: m.image.width != null ? m.image.width.toString() : undefined },
          { property: 'og:image:height', content: m.image.height != null ? m.image.height.toString() : undefined },
          { property: 'og:site_name', content: cinemas[0].name },
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:url', content: m.url || props.location.href },
          { name: 'twitter:title', content: m.title },
          { name: 'twitter:description', content: m.description },
          { name: 'twitter:image', content: m.image.url },
        ]}
      >
        <html lang="it" />
      </Helmet>
      )}
      </Location>
      <Header showBackButton={showBackButton} cinemas={cinemas}/>
      <Page>
        {children}
      </Page>
      <Footer mainCinemaId={cinemas[0].id}/>
      <ShareWidget />
      <TicketButton />
    </DesktopMargin>
  )
}

export default Layout

export interface PageGraphQlData {
  data: {
    mdsGraphQl: GQL.IRootQuery
    site: {
      siteMetadata: {
        mainCinema: string
      }
    }
  }
}

export const extractData = (query: PageGraphQlData) => {
  const cinemas = query.data.mdsGraphQl.cinemas!.edges!.map(c =>
    gqlToCinema(c!.node!)
  )
  const mainCinemaId: CinemaId = query.data.site.siteMetadata.mainCinema as CinemaId

  const first = cinemas.find(c => c.id == mainCinemaId)!
  const resCinemas = [first, ...cinemas.filter(c => c.id != mainCinemaId)]
  return {
    mainCinemaId,
    cinemas: resCinemas
  }
}

interface Theme {
  colors: {
    highlight: string
    attenuate: string
    slider1: string
    slider2: string
  }
}

export const THEMES: CinemasMap<Theme> = {
  mds: {
    colors:{
      highlight: '#FFDC00',
      attenuate: '#9C9C9C',
      slider1: '#FFDC00',
      slider2: '#209ADA',
    }
  },
  odeon6: {
    colors:{
      highlight: '#F9B42D',
      attenuate: '#9C9C9C',
      slider1: '#F9B42D',
      slider2: '#209ADA',
    }
  }
}

export const query = graphql`
fragment alldata on Query {
  site {
    siteMetadata {
      mainCinema
    }
  }
  mdsGraphQl {
    cinemas {
      edges {
        node {
          id
          name
          topMargin
          banner {
            url
            link
            image {
              id
              dominant_color
              mime_type
              tiny_thumbnail
              sizes {
                name
                width
                height
                url
              }
            }
          }
          backgroundImage
          heroImage
          showSlides
          noShowsMessage
          slug
          slides {
            url
            link
            image {
              id
              dominant_color
              mime_type
              tiny_thumbnail
              sizes {
                name
                width
                height
                url
              }
            }
          }
          comingSoon {
            cast
            description
            directors
            duration
            exerpt
            featuredImage {
              altText
              uri
              title
              slug
              status
              mimeType
              id
              guid
            }
            poster {
              id
              dominant_color
              mime_type
              tiny_thumbnail
              sizes {
                name
                width
                height
                url
              }
            }
            background {
              id
              dominant_color
              mime_type
              tiny_thumbnail
              sizes {
                name
                width
                height
                url
              }
            }
            specialEvent {
              event_type
              mds {
                dates_summary
                dates
                tickets
              }
              odeon6{
                dates_summary
                dates
                tickets
              }
            }
            genres
            id
            is3D
            is4K
            isVM14
            isVM18
            movieId
            releaseDate
            slug
            title
            trailerUrl
          }
          extraMovies {
            cast
            description
            directors
            duration
            exerpt
            featuredImage {
              altText
              uri
              title
              slug
              status
              mimeType
              id
              guid
            }
            poster {
              id
              dominant_color
              mime_type
              tiny_thumbnail
              sizes {
                name
                width
                height
                url
              }
            }
            background {
              id
              dominant_color
              mime_type
              tiny_thumbnail
              sizes {
                name
                width
                height
                url
              }
            }
            specialEvent {
              event_type
              mds {
                dates_summary
                dates
                tickets
              }
              odeon6{
                dates_summary
                dates
                tickets
              }
            }
            genres
            id
            is3D
            is4K
            isVM14
            isVM18
            movieId
            releaseDate
            slug
            title
            trailerUrl
          }
          nowShowingRaw
          nowShowing {
            cast
            description
            directors
            duration
            exerpt
            featuredImage {
              altText
              uri
              title
              slug
              status
              mimeType
              id
              guid
            }
            poster {
              id
              dominant_color
              mime_type
              tiny_thumbnail
              sizes {
                name
                width
                height
                url
              }
            }
            background {
              id
              dominant_color
              mime_type
              tiny_thumbnail
              sizes {
                name
                width
                height
                url
              }
            }
            specialEvent {
              event_type
              mds {
                dates_summary
                dates
                tickets
              }
              odeon6 {
                dates_summary
                dates
                tickets
              }
            }
            genres
            id
            is3D
            is4K
            isVM14
            isVM18
            movieId
            releaseDate
            slug
            title
            trailerUrl
            schedule {
              date
              time
              tags
              cinema {
                slug
              }
            }
          }
        }
      }
    }
  }
}`
