/// <reference path="../../typings/graphql.d.ts"/>

import * as R from 'ramda'
import moment from 'moment'
import { Cinema, CinemaId, Movie, Show, Times, Days, ImageLink, CINEMA_META, SpecialEvent, SpecialEventDetail, Image, ImageSize, Day, Shows, DayInfo } from "./model"
import { lintList, isNotEmpty, isEmpty, lintStringList, replaceAll, randomString } from './utils'

export interface CinemaMetadata {
  url: string
  title: string
  description: string
  keywords: string
}

export const parseNowShowingText = (cinemaId: CinemaId, text: string | null, idFactory=randomString): {shows: Show[], days: DayInfo[]} => {
  if (text == null) {
    return {shows: [], days: []}
  }
  let shows: Show[] = []
  let days: DayInfo[] = []
  const lines = text.split('\n').map(line => line.trim())
  let currentDay: DayInfo | null = null
  for (const line of lines) {
    if (line.length === 0) {
      continue
    }
    if (line.startsWith('#')) {
      if (currentDay != null) {
        days.push(currentDay)
      }
      const tokens = line.substring(1).split(' ').map(t => t.trim()).filter(t => t.length > 0)
      currentDay = {
        day: Days.fromDDMMYYYY(tokens[0]),
        dayTags: tokens.slice(1),
        movieIds: []
      }
      continue
    }
    if (currentDay == null) {
      throw new Error('No day specified for line: ' + line)
    }
    const [movieId, times] = line.split('||').map(s => s.trim())
    currentDay.movieIds.push(movieId)
    for (const timeString of times.split(',').map(s => s.trim())) {
      const [time, tag] = timeString.split(' ').map(s => s.trim())
      shows.push({
        id: idFactory(),
        movieId: movieId,
        cinemaId,
        day: currentDay!.day,
        time: Times.fromString(time),
        screen: '',
        tags: tag ? [tag] : []
      })
    }
  } 
  days.push(currentDay!)
  return {
    shows,
    days
  }
}

export const patchInPlace = (movies: Movie[], shows: Show[], append=false): void => {
  // HACK: I am overriding the movie shows using the raw list here
  // for added flexibility (especially around tags)
  const showsByMovie = Shows.groupByMovie(shows)
  for (const movie of movies) {
    if (showsByMovie[movie.id]?.length > 0) {
      const toKeep = append ?
        Object.values(movie.shows).reduce((acc, s) => [...acc, ...s], [])
        : []
      const updatedShows = toKeep.concat(showsByMovie[movie.id])
      movie.shows = R.groupBy((d => Days.toString(d.day)), updatedShows)
    }
  }
}


export const gqlToCinema = (cinema: GQL.ICinema): Cinema => {
  const cinemaId = (cinema.slug as CinemaId);
  const movies = cinema.nowShowing == null
    ? []
    : cinema.nowShowing.map(gqlToMovie(cinemaId))
  const comingSoon = cinema.comingSoon == null
    ? []
    : cinema.comingSoon.map(gqlToMovie(cinemaId))
  const extraMovies = cinema.extraMovies == null
    ? []
    : cinema.extraMovies.map(gqlToMovie(cinemaId))
  const parsedShows = parseNowShowingText(cinemaId, cinema.nowShowingRaw)
  patchInPlace(movies, parsedShows.shows)

  const banner = cinema.banner == null
    ? null
    : gqlToImageLink(cinema.banner)

  const slides = cinema.slides == null
    ? []
    : cinema.slides.map(gqlToImageLink)

  return {
    id: cinemaId,
    name: cinema.name,
    showSlides: cinema.showSlides,
    days: parsedShows.days,
    heroImage: cinema.heroImage,
    noShowsMessage: cinema.noShowsMessage,
    nowShowingRaw: cinema.nowShowingRaw,
    banner: banner,
    slides: slides,
    movies: movies,
    moviesComingSoon: comingSoon,
    extraMovies: extraMovies,
    topMargin: cinema.topMargin,
    backgroundImage: cinema.backgroundImage,
    description: CINEMA_META[cinemaId].description,
    keywords: CINEMA_META[cinemaId].keywords,
    url: CINEMA_META[cinemaId].url
  }
}

const lintDuration = (duration: string | null): number | null => {
  return duration == null || isNaN(parseInt(duration, 10))
    ? null
    : parseInt(duration, 10)
}

export const gqlToMovie = (cinemaId?: CinemaId) => (movie: GQL.IMovie): Movie => {
  const shows = lintList(movie.schedule).map(gqlToShow(cinemaId, movie.id))
  const event = gqlToEvent(cinemaId, movie)
  const releaseDate = movie.releaseDate == null
    ? null
    : Days.fromMoment(moment(movie.releaseDate, "DD-MM-YYYY"))
  return {
    id: movie.movieId,
    title: movie.title,
    slug: movie.slug,
    excerpt: movie.exerpt,
    description: movie.exerpt,
    category: lintStringList(movie.genres),
    cast: lintStringList(movie.cast),
    directors: lintStringList(movie.directors),
    duration: lintDuration(movie.duration),
    event,
    trailerUrl: movie.trailerUrl,
    releaseDate: releaseDate,
    picture: movie.background == null ? null : gqlToImage(movie.background),
    poster: gqlToImage(movie.poster!),
    is3D: movie.is3D || false,
    is4K: movie.is4K || false,
    isVM14: movie.isVM14 || false,
    isVM18: movie.isVM18 || false,
    shows: R.groupBy((d => Days.toString(d.day)), shows)
  }
}

export const gqlToImage = (image: GQL.IImage): Image => {
  const sizes: ImageSize[] = image.sizes!.map( size => ({
    width: parseInt(size!.width),
    height: parseInt(size!.height),
    url: adjustImageUrl(size!.url),
    name: size!.name
  }))
  return {
    mainColor: image.dominant_color,
    tinyThumbnail: image.tiny_thumbnail,
    sizes
  }
}

export const gqlToEvent = (cinemaId: CinemaId | undefined, movie: GQL.IMovie): SpecialEvent | null => {
  if (movie.specialEvent == null || isEmpty(movie.specialEvent.event_type)) {
    return null
  }
  return {
    type: movie.specialEvent.event_type!,
    mds: movie.specialEvent.mds == null ? null : {
      dates: lintStringList(movie.specialEvent.mds.dates).map(Days.fromString),
      dateSummary: movie.specialEvent.mds.dates_summary,
      tickets: lintStringList(movie.specialEvent.mds.tickets)
    },
    odeon6: movie.specialEvent.odeon6 == null ? null : {
      dates: lintStringList(movie.specialEvent.odeon6.dates).map(Days.fromString),
      dateSummary: movie.specialEvent.odeon6.dates_summary,
      tickets: lintStringList(movie.specialEvent.odeon6.tickets)
    }
  }
}

export const gqlToShow = (cinemaId: CinemaId | undefined, movieId: string | undefined) => (show: GQL.IShow): Show => {
  return {
    id: '',
    screen: '',
    day: Days.fromMoment(moment(show.date, "DD-MM-YYYY")),
    time: Times.fromString(show.time),
    tags: lintStringList(show.tags).map(replaceAll('-', ' ')).map(replaceAll('_', ' ')),
    cinemaId: cinemaId || (show.cinema && show.cinema.slug as CinemaId),
    movieId: movieId || (show.movie && show.movie.id)
  }
}

export const gqlToImageLink = (imageLink: GQL.IImageLink): ImageLink => {
  return {
    image: gqlToImage(imageLink.image),
    link: imageLink.link
  }
}

const adjustImageUrl = (imageUrl: string): string => {
  const bucket = (imageUrl.length % 3) + 1
  return imageUrl
    .replace('://www', '://m' + bucket)
    .replace('://admin', '://m' + bucket)
}
