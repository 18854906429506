import * as R from 'ramda'
import React, { useLayoutEffect } from 'react'
import { css, default as styled, keyframes } from 'styled-components'
import { Movie, CinemaId, Days, CINEMA_META, Cinema, Movies, Day, Show, ImageSize } from '../model/model';
import { Link } from 'gatsby';
import { ListItem } from './movie-list';
import { isEmpty, replaceAll } from '../model/utils';

export interface SSRAware {
  isSSR: boolean
}

export interface StyledComponentAware {
  className?: string
}

export const posterWidthAndHeight = (width: number) => `
  width: ${width}px;
  height: ${Math.floor(width * 1.454)}px;
`

export const isSSR = () => {
  return typeof window === 'undefined'
}

export const isMobile = (defaultTo=true) => {
  return typeof window !== 'undefined'
    ? window.innerWidth < 700
    : defaultTo
}

// Hook
export function useLockBodyScroll(toBeLocked) {
  useLayoutEffect(() => {
    document.body.style.overflow = toBeLocked
      ? 'hidden' : 'auto'
   return () => {document.body.style.overflow = 'auto'};
   }, [toBeLocked]);
}

export const hoverHighlight = css`
  @media (any-hover) {
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }
`

export const Page = styled.section`
  flex-direction: column;
  margin: 0 5px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
`

interface ListProps {
  wrap: 'wrap' | 'nowrap'
}
export const List = styled.ul<ListProps>`
  display: flex;
  ${props => props.wrap === 'wrap'
  ? 'flex-wrap: wrap;'
  : ''
  }
  flex-direction: row;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  list-style: none;
  margin: 0;
`


interface ItemProps {
  state: 'normal' | 'selected' | 'disabled'
}

// This horrible hack is due to styled components not playing well with SSR
// https://github.com/gatsbyjs/gatsby/issues/5993
// Also note https://github.com/facebook/react/issues/11128
// ...that was 1.5d I will never get back..
// My understanding is that this is specifically due to the properties being passed
// to the component, hence separating SSR_Item from the property-driven Item
// My understanding might be incorrect, but can't be bothered to investigate any further now
export const SSR_Item = styled.li`
  display: flex;
  flex-shrink: 0;
  padding: 5px;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  user-select: none;
  color: #FFFFFF;
`

export const MinimalItem = styled(SSR_Item)<ItemProps>`
  ${props => props.state === 'normal'
  ? 'color: #FFFFFF;'
  : ''
  }
  ${props => props.state === 'selected'
  ? 'color: ' + props.theme.colors.highlight + ';'
  : ''
  }
  ${props => props.state === 'disabled'
  ? 'color: ' + props.theme.colors.attenuate + ';'
  : ''
  }
`

export const Item = styled(MinimalItem)<ItemProps>`
  ${props => props.state === 'normal'
  ? 'border: 2px solid #FFFFFF;'
  : ''
  }
  ${props => props.state === 'selected'
  ? 'border: 2px solid ' + props.theme.colors.highlight + ';'
  : ''
  }
  ${props => props.state === 'disabled'
  ? 'border: 2px solid ' + props.theme.colors.attenuate + ';'
  : ''
  }
`

export const HighlightSpan = styled('span')`
  .cinema-name {
    ${props => 'color: ' + props.theme.colors.highlight + ';'}
    opacity: 0.8;
  }
`

export const LinkItem = styled(Item)`
  cursor: pointer;
`

export const ItemTagHighlight = styled.span<{state: 'normal' | 'disabled'}>`
padding-left: 10px;
${props => props.state === 'normal'
? 'color: ' + props.theme.colors.highlight + ';'
: ''
}
${props => props.state === 'disabled'
? 'color: ' + props.theme.colors.attenuate + ';'
: ''
}
`

export const EmbedContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  & iframe, & object, & embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

/**
 * Smart link component.
 * - if the url provided is null/undefined, just return the children
 * - if the url provided is internal, create a <Link>
 * - if the url provided is external, create a <a>
 */
export const SmartLink: React.SFC<{mainCinemaId: CinemaId, url: string | null | undefined, onClick: () => void, className?: string}> = ({mainCinemaId, url, className, onClick, children}) => {
  if (isEmpty(url)) {
    return <>{children}</>
  }
  return url.startsWith(CINEMA_META[mainCinemaId].url)
    ? <Link className={className}
            to={url.slice(CINEMA_META[mainCinemaId].url.length)}
            onClick={onClick}>
        {children}
      </Link>
    : <a className={className} href={url}
         onClick={onClick}>
        {children}
      </a>
}

export const fade = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity:1;
  }
`;

export const Fader = styled.div`
  animation: ${fade} 0.2s linear;
`

export const Routing = {
  toMoviePage(m: Movie): string {
    return `/movie/${m.slug}`
  }
}

export const MovieVM = {
  getReleaseMessage: (movie: Movie, mainCinemaId: CinemaId) => {
    const text = movie.event && movie.event[mainCinemaId] && movie.event[mainCinemaId]!.dateSummary
      ? movie.event[mainCinemaId]!.dateSummary
      : movie.releaseDate == null
        ? 'Prossimamente'
        : `Dal ${Days.toString(movie.releaseDate)}`

    const normalized = text
      .replace(/multiplex delle stelle/i, '<span class="cinema-name">Multiplex delle Stelle</span>')
      .replace(/cinema odeon/i, '<span class="cinema-name">Cinema Odeon 6</span>');
    
    return <HighlightSpan dangerouslySetInnerHTML={{ __html: normalized}}/>;
  },
  computeMovieListForNowShowing: (cinemas: Cinema[], movies: Movie[], today: Day): ListItem[] => {
    return movies.map(m => {
      return {
        movie: m,
        tags: [...MovieVM.getTagsForShowingElsewhere(cinemas, m), ...MovieVM.getTagsForEvent(m), ...MovieVM.getMovieTags(m, today)]
      }
    })
  },
  computeMovieListForComingSoon: (cinemas: Cinema[], movies: Movie[], today: Day): ListItem[] => {
    return movies.map(m => {
      return {
        movie: m,
        tags: [...MovieVM.getTagsForEvent(m), ...MovieVM.getMovieTags(m, today)]
      }
    })
  },
  computeMovieListForEvents: (cinemas: Cinema[], movies: Movie[], today: Day): ListItem[] => {
    return movies.map(m => {
      return {
        movie: m,
        tags: [...MovieVM.getTagsForEvent(m), ...MovieVM.getMovieTags(m, today)]
      }
    })
  },
  getTagsForEvent: (movie: Movie): string[] => {
    return movie.event == null ? [] : [movie.event.type]
  },
  getTagsForEventElsewhere:  (cinemas: Cinema[], movie: Movie): string[] => {
    if (movie.event == null) {
      return []
    }
    const mainCinemaDates = movie.event[cinemas[0].id] == null
      ? 0
      : movie.event[cinemas[0].id]!.dates.length
    const otherCinemaDates = movie.event[cinemas[1].id] == null
      ? 0
      : movie.event[cinemas[1].id]!.dates.length
    return mainCinemaDates === 0 && otherCinemaDates > 0
      ? [cinemas[1].id]
      : []
  },
  getTagsForShowingElsewhere: (cinemas: Cinema[], movie: Movie): string[] => {
    const mainCinemaId = cinemas[0].id
    const shows = Movies.allShows(movie)
    const localShows = shows.filter(s => s.cinemaId === mainCinemaId)
    return shows.length > 0 && localShows.length == 0
      ? [cinemas[1].id]
      : []
  },
  getMovieTags: (movie: Movie, today: Day): string[] => {
    const threeDtag = movie.is3D || R.any(s => s.tags.includes('3D'), Movies.allShows(movie))
      ? ['3D']
      : []
    const vmTag = movie.isVM18
      ? ['VM18']
      : movie.isVM14
        ? ['VM14']
        : []
    const movieDays = Movies.getPresentAndFutureDays(today, [movie])
    const startDateTags = movie.event == null && movieDays.length > 0  && Days.isAfter(today)(movieDays[0])
      ? ['Da ' + Days.weekDayLong(movieDays[0])]
      : []

    return [...startDateTags, ...threeDtag, ...vmTag]
  }
}

export const ShowsVM = {
  getCommonTags: (shows: Show[]): string[] => {
    const commonTags = shows.reduce( (acc: string[] | null, day) => {
      if (acc == null) {
        return day.tags
      } else {
        return R.intersection(acc, day.tags)
      }
    }, null)
    return commonTags || []
  }
}

export interface PageMeta {
  type: 'video.movie' | 'website' | 'article'
  title: string
  description: string
  keywords: string
  image: ImageSize
  url?: string
}

export const truncateToWord = (maxLetters: number, text: string): string => {
  return text.slice(0, maxLetters).split(' ').slice(0, -1).join(' ')
}

export const movieToMeta = (cinema: Cinema, movie: Movie): PageMeta => ({
    type: 'video.movie',
    url: cinema.url + Routing.toMoviePage(movie),
    title: `${movie.title} - ${cinema.name}`,
    description: movie.description.length > 190
      ? truncateToWord(190, replaceAll('<br>', '-')(movie.description)) + '...'
      : movie.description,
    keywords: `${cinema.keywords}, ${movie.title}, ${movie.directors.join(',')}, ${movie.cast.join(',')}`,
    image: movie.picture != null
    ? R.last(R.sortBy(p => p.width || 0, movie.picture.sizes))!
    : movie.poster != null
      ? R.last(R.sortBy(p => p.width || 0, movie.poster.sizes))!
      : CINEMA_META[cinema.id].logo
})

export const cinemaToMeta = (cinema: Cinema, page?: string): PageMeta => ({
  type: 'website',
  title: cinema.name + (page != null ? page : ''),
  description: cinema.description,
  keywords: cinema.keywords,
  image: CINEMA_META[cinema.id].logo
})