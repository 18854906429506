import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { hoverHighlight, useLockBodyScroll } from './utils';
import { Cinema, CinemaId } from '../model/model';
import { Location } from '@reach/router';
import { Tracking } from '../model/utils';

const NavWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  list-style: none;
  margin: 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8em;
  letter-spacing: 0.1em;
  & li {
    margin: 0 0 0 2rem;
  }
`

const TitleLink = styled(Link)`
  color: white;
  text-decoration: none;
  ${hoverHighlight}
`

const TitleHref = styled.a`
  color: white;
  text-decoration: none;
  ${hoverHighlight}
`

const MdsNav = styled.nav`
  display: none;
  @media (min-width: 700px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
`

const ResponsiveNav = styled.div`
  flex-grow: 1;
`

const NavExpandButton = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  @media (min-width: 700px) {
    display: none;
  }
`

const NavCollapseButton = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
`

interface NavOverlayProps {
  isOpen: boolean
}
const NavOverlay = styled.div<NavOverlayProps>`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  background-color: rgba(0,0,0,0.85);
  left: 0;
  z-index: 10;
  top: ${props => props.isOpen
    ? '0'
    : '150vh'
  };
  transition: 200ms top;
`

const OverlayItems = styled.ul`
  display: flex;
  position: absolute;
  top: 20%;
  margin: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  list-style: none;
  width: 100%;
  text-transform: uppercase;
  font-size: 1.5rem;
`

const OverlayItem = styled.li`
  width: 100%;
  margin: 0;
  padding: 1em 0;
  border-bottom: 1px solid #FFF;
  &:first-child {
    border-top: 1px solid #FFF;
  }
`

const MobileNavContainer = styled.div`
  display: block;
  @media (min-width: 700px) {
    display: none;
  }
`
interface NavItem {
  path: string
  title: string
  trackingCode: string
  showInMobile: boolean
  showInDesktop: boolean
  showInCinema?: CinemaId[]
}

const items: NavItem[] = [
  { path: '/',                  title: 'Home',            trackingCode: 'home',             showInDesktop: false,   showInMobile: true},
  // { path: '/faq-riapertura',    title: 'FAQ Riapertura',  trackingCode: 'faq-riapertura',   showInDesktop: true,    showInMobile: true},
  { path: '/cinema-a-2-euro',   title: 'Cinema a €2',     trackingCode: 'cinema-a-2-euro',  showInDesktop: true,    showInMobile: true, showInCinema: ['mds']},
  { path: '/prossimamente',     title: 'Prossimamente',   trackingCode: 'prossimamente',    showInDesktop: true,    showInMobile: true},
  { path: '/eventi',            title: 'Eventi',          trackingCode: 'eventi',           showInDesktop: true,    showInMobile: true},
  { path: '/informazioni',      title: 'Informazioni',    trackingCode: 'informazioni',     showInDesktop: true,    showInMobile: true},
]

const MobileNav: React.SFC<NavProps> = ({cinemas}) => {
  const [isOpen, setIsOpen] = useState(false);
  useLockBodyScroll(isOpen)

  return (
    <Location>
      {props => (
        <MobileNavContainer>
          <NavExpandButton onClick={() => {setIsOpen(true)}}>
            <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
              <line x1="2" y1="5" x2="28" y2="5" stroke="#FFF" strokeWidth="2"/>
              <line x1="2" y1="15" x2="28" y2="15" stroke="#FFF" strokeWidth="2"/>
              <line x1="2" y1="25" x2="28" y2="25" stroke="#FFF" strokeWidth="2"/>
            </svg>
          </NavExpandButton>
          <NavOverlay isOpen={isOpen} onClick={() => {setIsOpen(false)}}>
            <NavCollapseButton onClick={() => {setIsOpen(false)}}>
              <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
                <line x1="2" y1="2" x2="28" y2="28" stroke="#FFF" strokeWidth="2"/>
                <line x1="2" y1="28" x2="28" y2="2" stroke="#FFF" strokeWidth="2"/>
              </svg>
            </NavCollapseButton>
            <OverlayItems>
              {
                items
                  .filter( item => item.showInCinema ? item.showInCinema.includes(cinemas[0].id) : true)
                  .filter( item => item.showInMobile )
                  .filter( item => props.location.pathname !== item.path )
                  .map( item => (
                    <OverlayItem key={item.path}>
                      <TitleLink to={item.path} onClick={
                        () => Tracking.trackNavigation('nav', item.trackingCode)
                      }>{item.title}</TitleLink>
                    </OverlayItem>
                  ))
              }
              <OverlayItem><TitleHref href={cinemas[1].url}
                onClick={() => {Tracking.trackNavigation('nav', 'altro-cinema')}}>Vai al {cinemas[1].name}</TitleHref></OverlayItem>
            </OverlayItems>

          </NavOverlay>
        </MobileNavContainer>
      )
    }
    </Location>
  )
}


const DesktopNav: React.SFC<NavProps> = ({cinemas}) => (
  <MdsNav>
    <NavWrapper>
      {
        items
        .filter( item => item.showInCinema ? item.showInCinema.includes(cinemas[0].id) : true)
        .filter( item => item.showInDesktop)
          .map( item => (
            <li key={item.path}>
              <TitleLink to={item.path}
                onClick={() => Tracking.trackNavigation('nav', item.trackingCode)}>{item.title}</TitleLink>
            </li>
          ))
      }
      <li><TitleHref href={cinemas[1].url}
        onClick={() => {Tracking.trackNavigation('nav', 'altro-cinema')}}>Vai al {cinemas[1].name}</TitleHref></li>
    </NavWrapper>
  </MdsNav>
)

interface NavProps {
  cinemas: Cinema[]
}
const Nav: React.SFC<NavProps> = ({cinemas}) => (
  <ResponsiveNav>
    <MobileNav cinemas={cinemas}/>
    <DesktopNav cinemas={cinemas}/>
  </ResponsiveNav>
)

export default Nav
